import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Button} from 'antd';
import s from "./Sidebar.module.scss";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    AvailableUserMenuItem,
    CheckIsGoogleIntegrated,
    IntegrateWithGoogleThunk, LogoutFromGoogleThunk, selectAvailableMenuItems,
    selectIsIntegratedWithGoogle,
    selectUserData
} from "../../store/userReducer";
import {ReactComponent as GoogleCalendarIcon} from './../../img/icons/google-calendar.svg'
import {ReactComponent as GoogleIntegratedIcon} from './../../img/icons/google-integrated.svg'
import {ReactComponent as DoneIcon} from './../../img/icons/done.svg'
import {onChangeCurrentGridTab, onSetIsBrokerageDataUpdating} from "../../store/propertiesReducer";


const Sidebar = () => {
    const dispatch = useAppDispatch()
    const userData = useAppSelector(selectUserData)
    const currentPath = window.location.pathname
    const [isControlPanelOpened, setIsControlPanelOpened] = useState<boolean>(false)
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const isGoogleIntegrated = useAppSelector(selectIsIntegratedWithGoogle)
    const availableMenuItems = useAppSelector(selectAvailableMenuItems)
    const propertiesMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Properties')
    const reportsMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Reports')
    const auditMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Audit')
    const parametersMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.Parameters')
    const contactPropertiesMenuItem = availableMenuItems!?.find((i: AvailableUserMenuItem) => i.OBJECT_NAME === 'Menu.PropertyContacts')

    const handleItemHover = (itemName: string) => {
        setHoveredItem(itemName);
    };
    const handleItemLeave = () => {
        setHoveredItem(null);
    };


    const onHideMenu = () => {
        setIsMenuOpened(!isMenuOpened)
    }

    const onOpenNestedMenu = () => {
        dispatch(onSetIsBrokerageDataUpdating(false))
        if (isMenuOpened) {
            setIsControlPanelOpened(!isControlPanelOpened)
        }
    }

    const integrateWithGoogle = () => {
        dispatch(IntegrateWithGoogleThunk())
            .then((resp) => {
                const link = resp.payload as string
                if (link?.length) {
                    window.location.href = link
                }
            })
    }


    const logoutFromGoogle = () => {
        dispatch(LogoutFromGoogleThunk())
    }

    useEffect(() => {
        if (userData && userData.user_ref) {
            dispatch(CheckIsGoogleIntegrated(Number(userData.user_ref)));
        }
    }, [dispatch, userData?.user_ref]);


    const onMenuItemClick = () => {
        dispatch(onSetIsBrokerageDataUpdating(false))
        if (currentPath === '/properties') {
            dispatch(onChangeCurrentGridTab('All Properties/REQ'))
        }
    }


    return (
        <div
            className={s.menu}
            style={{
                minWidth: isMenuOpened ? '208px' : '54px',
                maxWidth: isMenuOpened ? '208px' : '54px'
            }}
        >
            <>
                {
                    isMenuOpened
                        ?
                        <div
                            className={currentPath === '/notifications' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            onClick={onMenuItemClick}
                        >
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 22.5V12.5H15V22.5M3 9.5L12 2.5L21 9.5V20.5C21 21.0304 20.7893 21.5391 20.4142 21.9142C20.0391 22.2893 19.5304 22.5 19 22.5H5C4.46957 22.5 3.96086 22.2893 3.58579 21.9142C3.21071 21.5391 3 21.0304 3 20.5V9.5Z"
                                    stroke={currentPath === '/notifications' ? '#fff' : "#667085"} strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            <NavLink
                                aria-disabled={true}
                                to={'/notifications'}
                            >
                                Dashboard
                            </NavLink>

                        </div>
                        :
                        <>
                            <div
                                className={currentPath === '/notifications' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    lineHeight: '0'
                                }}
                                onMouseEnter={() => handleItemHover('Notifications')}
                                onMouseLeave={handleItemLeave}
                                onClick={onMenuItemClick}
                            >
                                <NavLink
                                    aria-disabled={true}
                                    to={'/notifications'}
                                    style={{
                                        marginLeft: '0',
                                        lineHeight: '0',
                                    }}
                                >
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 22.5V12.5H15V22.5M3 9.5L12 2.5L21 9.5V20.5C21 21.0304 20.7893 21.5391 20.4142 21.9142C20.0391 22.2893 19.5304 22.5 19 22.5H5C4.46957 22.5 3.96086 22.2893 3.58579 21.9142C3.21071 21.5391 3 21.0304 3 20.5V9.5Z"
                                            stroke={currentPath === '/notifications' ? '#fff' : "#667085"}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </NavLink>
                                {hoveredItem && hoveredItem === 'Notifications' && (
                                    <div style={{top: 3, left: 53}}
                                         className={currentPath === '/notifications' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                        {hoveredItem}
                                    </div>
                                )}
                            </div>
                        </>
                }
                {isMenuOpened
                    ?
                    <div
                        className={
                            currentPath === '/requirements'
                            || currentPath === '/requirements/create-req'
                            || currentPath === '/requirements/properties'
                            || currentPath === '/reqs-searched-by-contacts'
                            || currentPath === '/requirements/create-req-contact'
                            || currentPath === '/requirements/edit-req/:id'
                                ? `${s.menu__item} ${s.menu__item_active}`
                                : s.menu__item
                        }
                        onClick={onMenuItemClick}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 20V10M12 20V4M6 20V14"
                                  stroke={
                                      currentPath === '/requirements'
                                      || currentPath === '/requirements/create-req'
                                      || currentPath === '/requirements/properties'
                                      || currentPath === '/reqs-searched-by-contacts'
                                      || currentPath === '/requirements/create-req-contact'
                                      || currentPath === '/requirements/edit-req/:id' ? '#fff' : "#667085"}
                                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <NavLink
                            to={'/requirements'}
                        >
                            Requirements
                        </NavLink>


                    </div>
                    :
                    <>

                        <div
                            className={currentPath === '/requirements'
                            || currentPath === '/requirements/create-req'
                            || currentPath === '/requirements/properties'
                            || currentPath === '/reqs-searched-by-contacts'
                            || currentPath === '/requirements/create-req-contact'
                            || currentPath === '/requirements/edit-req/:id' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            onClick={onMenuItemClick}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                lineHeight: '0'
                            }}
                            onMouseEnter={() => handleItemHover('Requirements')}
                            onMouseLeave={handleItemLeave}
                        >
                            <NavLink
                                to={'/requirements'}
                                style={{
                                    marginLeft: '0',
                                    lineHeight: '0',
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 20V10M12 20V4M6 20V14"
                                          stroke={currentPath === '/requirements'
                                          || currentPath === '/requirements/create-req'
                                          || currentPath === '/requirements/properties'
                                          || currentPath === '/reqs-searched-by-contacts'
                                          || currentPath === '/requirements/create-req-contact'
                                          || currentPath === '/requirements/edit-req/:id' ? '#fff' : "#667085"}
                                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </NavLink>

                            {hoveredItem && hoveredItem === 'Requirements' && (
                                <div style={{top: 3, left: 53}}
                                     className={currentPath === '/requirements'
                                     || currentPath === '/requirements/create-req'
                                     || currentPath === '/requirements/properties'
                                     || currentPath === '/reqs-searched-by-contacts'
                                     || currentPath === '/requirements/create-req-contact'
                                     || currentPath === '/requirements/edit-req/:id' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                    {hoveredItem}
                                </div>
                            )}
                        </div>
                    </>

                }

                {
                    propertiesMenuItem
                    &&
                    propertiesMenuItem?.IS_ACCESS === 1
                    &&
                    <>
                        {
                            isMenuOpened
                                ?
                                <div
                                    className={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    onClick={onMenuItemClick}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"
                                              stroke={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? '#fff' : "#667085"}
                                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {isMenuOpened
                                        &&
                                        <NavLink
                                            className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                                            to={'/properties'}
                                        >
                                            Properties
                                        </NavLink>
                                    }

                                </div>
                                :
                                <div
                                    className={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        lineHeight: '0'
                                    }}
                                    onMouseEnter={() => handleItemHover('Properties')}
                                    onMouseLeave={handleItemLeave}
                                    onClick={onMenuItemClick}
                                >


                                    <NavLink
                                        className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                                        to={'/properties'}
                                        style={{
                                            marginLeft: '0',
                                            lineHeight: '0',
                                        }}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"
                                                  stroke={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? '#fff' : "#667085"}
                                                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </NavLink>
                                    {hoveredItem && hoveredItem === 'Properties' && (
                                        <div style={{top: 3, left: 53}}
                                             className={currentPath === '/properties' || currentPath === '/properties/brokerage/reqs' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                            {hoveredItem}
                                        </div>
                                    )}

                                </div>
                        }

                    </>
                }

                {
                    contactPropertiesMenuItem
                    &&
                    contactPropertiesMenuItem?.IS_ACCESS === 1
                    &&
                    <>
                        {
                            isMenuOpened
                                ?
                                <div
                                    className={currentPath === '/contactProperties' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    onClick={onMenuItemClick}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 6.74952V14.9995M15 8.99952V17.2495M15.503 20.7475L20.378 18.3105C20.759 18.1205 21 17.7305 21 17.3045V4.81952C21 3.98352 20.12 3.43952 19.372 3.81352L15.503 5.74752C15.186 5.90652 14.813 5.90652 14.497 5.74752L9.503 3.25152C9.34682 3.17346 9.17461 3.13281 9 3.13281C8.82539 3.13281 8.65318 3.17346 8.497 3.25152L3.622 5.68852C3.24 5.87952 3 6.26952 3 6.69452V19.1795C3 20.0155 3.88 20.5595 4.628 20.1855L8.497 18.2515C8.814 18.0925 9.187 18.0925 9.503 18.2515L14.497 20.7485C14.814 20.9065 15.187 20.9065 15.503 20.7485V20.7475Z"
                                            stroke={currentPath === '/contactProperties' ? '#fff' : "#667085"} strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    {isMenuOpened
                                        &&
                                        <NavLink
                                            className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                                            to={'/contactProperties'}
                                        >
                                            Properties Contacts
                                        </NavLink>
                                    }

                                </div>
                                :
                                <div
                                    className={currentPath === '/contactProperties' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        lineHeight: '0'
                                    }}
                                    onMouseEnter={() => handleItemHover('Properties Contacts')}
                                    onMouseLeave={handleItemLeave}
                                    onClick={onMenuItemClick}
                                >


                                    <NavLink
                                        className={(navData) => (navData.isActive ? `${s.link} ${s.link_active}` : s.link)}
                                        to={'/contactProperties'}
                                        style={{
                                            marginLeft: '0',
                                            lineHeight: '0',
                                        }}
                                    >
                                        {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                        {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*    <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"*/}
                                        {/*          stroke={currentPath === '/contactProperties' ? '#fff' : "#667085"}*/}
                                        {/*          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*</svg>*/}
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 6.74952V14.9995M15 8.99952V17.2495M15.503 20.7475L20.378 18.3105C20.759 18.1205 21 17.7305 21 17.3045V4.81952C21 3.98352 20.12 3.43952 19.372 3.81352L15.503 5.74752C15.186 5.90652 14.813 5.90652 14.497 5.74752L9.503 3.25152C9.34682 3.17346 9.17461 3.13281 9 3.13281C8.82539 3.13281 8.65318 3.17346 8.497 3.25152L3.622 5.68852C3.24 5.87952 3 6.26952 3 6.69452V19.1795C3 20.0155 3.88 20.5595 4.628 20.1855L8.497 18.2515C8.814 18.0925 9.187 18.0925 9.503 18.2515L14.497 20.7485C14.814 20.9065 15.187 20.9065 15.503 20.7485V20.7475Z"
                                                stroke={currentPath === '/contactProperties' ? '#fff' : "#667085"} strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                    </NavLink>
                                    {hoveredItem && hoveredItem === 'Properties Contacts' && (
                                        <div style={{top: 3, left: 53}}
                                             className={currentPath === '/contactProperties' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                            {hoveredItem}
                                        </div>
                                    )}

                                </div>
                        }

                    </>
                }


                {
                    isMenuOpened
                        ?

                        <div
                            className={currentPath === '/activitiesGrid' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            onClick={onMenuItemClick}
                        >
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="eva:activity-fill">
                                    <g id="evaActivityFill0">
                                        <g id="evaActivityFill1">
                                            <path id="evaActivityFill2"
                                                  d="M14.33 20.5002H14.12C13.6968 20.4582 13.2979 20.2824 12.9814 19.9983C12.6649 19.7141 12.4472 19.3365 12.36 18.9202L9.68 6.50023L6.92 12.9002C6.84193 13.0791 6.71323 13.2312 6.54975 13.3378C6.38627 13.4445 6.19517 13.5009 6 13.5002H3C2.73478 13.5002 2.48043 13.3949 2.29289 13.2073C2.10536 13.0198 2 12.7654 2 12.5002C2 12.235 2.10536 11.9807 2.29289 11.7931C2.48043 11.6056 2.73478 11.5002 3 11.5002H5.34L7.85 5.71023C8.01896 5.32169 8.30695 4.99685 8.67244 4.78254C9.03793 4.56823 9.46203 4.47552 9.88361 4.51779C10.3052 4.56006 10.7024 4.73512 11.0181 5.01772C11.3338 5.30033 11.5515 5.67588 11.64 6.09023L14.32 18.5002L17.08 12.1202C17.155 11.9376 17.2824 11.7813 17.4461 11.6709C17.6099 11.5606 17.8026 11.5012 18 11.5002H21C21.2652 11.5002 21.5196 11.6056 21.7071 11.7931C21.8946 11.9807 22 12.235 22 12.5002C22 12.7654 21.8946 13.0198 21.7071 13.2073C21.5196 13.3949 21.2652 13.5002 21 13.5002H18.66L16.15 19.2902C15.9967 19.6467 15.7431 19.951 15.4199 20.1658C15.0967 20.3807 14.718 20.4969 14.33 20.5002Z"
                                                  fill={currentPath === '/activitiesGrid' || currentPath === '/organizationActivities' || currentPath === '/organizationActivitiesProperties' ? '#fff' : "#667085"}/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {isMenuOpened
                                &&
                                <NavLink
                                    to={'/activitiesGrid'}
                                >
                                    Activities
                                </NavLink>
                            }

                        </div>
                        :
                        <div
                            className={currentPath === '/activitiesGrid' || currentPath === '/organizationActivities' || currentPath === '/organizationActivitiesProperties' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                lineHeight: '0',
                            }}
                            onMouseEnter={() => handleItemHover('Activities')}
                            onMouseLeave={handleItemLeave}
                            onClick={onMenuItemClick}
                        >


                            <NavLink
                                to={'/activitiesGrid'}
                                style={{
                                    marginLeft: '0',
                                    lineHeight: '0',
                                }}
                            >
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="eva:activity-fill">
                                        <g id="evaActivityFill0">
                                            <g id="evaActivityFill1">
                                                <path id="evaActivityFill2"
                                                      d="M14.33 20.5002H14.12C13.6968 20.4582 13.2979 20.2824 12.9814 19.9983C12.6649 19.7141 12.4472 19.3365 12.36 18.9202L9.68 6.50023L6.92 12.9002C6.84193 13.0791 6.71323 13.2312 6.54975 13.3378C6.38627 13.4445 6.19517 13.5009 6 13.5002H3C2.73478 13.5002 2.48043 13.3949 2.29289 13.2073C2.10536 13.0198 2 12.7654 2 12.5002C2 12.235 2.10536 11.9807 2.29289 11.7931C2.48043 11.6056 2.73478 11.5002 3 11.5002H5.34L7.85 5.71023C8.01896 5.32169 8.30695 4.99685 8.67244 4.78254C9.03793 4.56823 9.46203 4.47552 9.88361 4.51779C10.3052 4.56006 10.7024 4.73512 11.0181 5.01772C11.3338 5.30033 11.5515 5.67588 11.64 6.09023L14.32 18.5002L17.08 12.1202C17.155 11.9376 17.2824 11.7813 17.4461 11.6709C17.6099 11.5606 17.8026 11.5012 18 11.5002H21C21.2652 11.5002 21.5196 11.6056 21.7071 11.7931C21.8946 11.9807 22 12.235 22 12.5002C22 12.7654 21.8946 13.0198 21.7071 13.2073C21.5196 13.3949 21.2652 13.5002 21 13.5002H18.66L16.15 19.2902C15.9967 19.6467 15.7431 19.951 15.4199 20.1658C15.0967 20.3807 14.718 20.4969 14.33 20.5002Z"
                                                      fill={currentPath === '/activitiesGrid' || currentPath === '/organizationActivities' || currentPath === '/organizationActivitiesProperties' ? '#fff' : "#667085"}/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </NavLink>
                            {hoveredItem && hoveredItem === 'Activities' && (
                                <div style={{top: 3, left: 53}}
                                     className={currentPath === '/activitiesGrid' || currentPath === '/organizationActivities' || currentPath === '/organizationActivitiesProperties' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                    {hoveredItem}
                                </div>
                            )}

                        </div>

                }
                {reportsMenuItem
                    &&
                    reportsMenuItem?.IS_ACCESS === 1
                    &&
                    <div
                        style={{
                            margin: '4px 0'
                        }}
                    >
                        {isMenuOpened
                            ?
                            <div
                                onClick={onOpenNestedMenu}
                                className={s.menu__item}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 11L12 14L22 4M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"
                                        stroke={"#667085"} strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <>
                                    <p>Control Panel</p>
                                    <svg style={{
                                        transform: isControlPanelOpened ? 'rotate(180deg)' : 'none'
                                    }
                                    } className={s.menu__item_arrow} width="20" height="20" viewBox="0 0 20 20"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 7.5L10 12.5L15 7.5" stroke="#98A2B3" strokeWidth="1.66667"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                    </svg>
                                </>
                            </div>
                            :
                            <div
                                className={currentPath === '/control-panel'
                                || currentPath === '/control-panel/activity-report'
                                || currentPath === '/control-panel/agent-last-contact'
                                || currentPath === '/control-panel/property-last-contact'
                                || currentPath === '/control-panel/requirement-last-contact'
                                || currentPath === '/control-panel/valuated-properties-last-contact'
                                || currentPath === '/fraud-reports' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                onMouseEnter={() => handleItemHover('Control panel')}
                                onMouseLeave={handleItemLeave}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    lineHeight: '0'
                                }}
                            >
                                <NavLink
                                    to={'/control-panel'}
                                    style={{
                                        marginLeft: '0',
                                        lineHeight: '0',
                                    }}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 11L12 14L22 4M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"
                                            stroke={currentPath === '/control-panel'
                                            || currentPath === '/control-panel/activity-report'
                                            || currentPath === '/control-panel/agent-last-contact'
                                            || currentPath === '/control-panel/property-last-contact'
                                            || currentPath === '/control-panel/requirement-last-contact'
                                            || currentPath === '/control-panel/valuated-properties-last-contact'
                                            || currentPath === '/fraud-reports' ? '#fff' : "#667085"}
                                            strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </NavLink>
                                {hoveredItem && hoveredItem === 'Control panel' && (
                                    <div style={{top: 3, left: 43, padding: 0}}
                                         className={`${s.menu__item_hovered} ${s.menu__item_hovered_nested}`}>
                                        <div style={{
                                            width: '100%'
                                        }}>
                                            <div
                                                className={currentPath === '/control-panel'
                                                || currentPath === '/control-panel/activity-report'
                                                || currentPath === '/control-panel/agent-last-contact'
                                                || currentPath === '/control-panel/property-last-contact'
                                                || currentPath === '/control-panel/requirement-last-contact'
                                                || currentPath === '/control-panel/valuated-properties-last-contact'
                                                    ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                                onClick={onMenuItemClick}
                                            >
                                                <NavLink
                                                    to={'/control-panel'}
                                                >
                                                    Activity Reports
                                                </NavLink>
                                            </div>
                                            <div
                                                className={currentPath === '/fraud-reports' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                                onClick={onMenuItemClick}
                                            >
                                                <NavLink
                                                    to={'/fraud-reports'}
                                                >
                                                    Fraud Reports
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {isControlPanelOpened
                            &&
                            <div className={s.nestedMenu} style={{
                                display: !isMenuOpened ? 'none' : 'flex'
                            }}>
                                <svg width="1" height="82" viewBox="0 0 1 82" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="1" height="82" fill="#667085"/>
                                </svg>
                                <div>
                                    <div
                                        className={currentPath === '/control-panel'
                                        || currentPath === '/control-panel/activity-report'
                                        || currentPath === '/control-panel/agent-last-contact'
                                        || currentPath === '/control-panel/property-last-contact'
                                        || currentPath === '/control-panel/requirement-last-contact'
                                        || currentPath === '/control-panel/valuated-properties-last-contact'
                                            ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                        onClick={onMenuItemClick}
                                    >
                                        <NavLink
                                            to={'/control-panel'}
                                        >
                                            Activity Reports
                                        </NavLink>
                                    </div>
                                    <div
                                        className={currentPath === '/fraud-reports' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                                        onClick={onMenuItemClick}
                                    >
                                        <NavLink
                                            to={'/fraud-reports'}
                                        >
                                            Fraud Reports
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {auditMenuItem
                &&
                auditMenuItem.IS_ACCESS === 1
                    ?
                    isMenuOpened
                        ?

                        <div
                            className={currentPath === '/audit' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            onClick={onMenuItemClick}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.382 5.968C7.97574 4.69107 9.9578 3.99674 12 4C14.125 4 16.078 4.736 17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C19.9292 8.50526 20.5443 9.82713 20.8258 11.2369C21.1073 12.6467 21.0469 14.1034 20.6498 15.4851C20.2527 16.8668 19.5304 18.1333 18.5433 19.1785C17.5562 20.2237 16.3331 21.0172 14.9764 21.4926C13.6196 21.9681 12.1687 22.1116 10.7452 21.9111C9.32158 21.7107 7.96672 21.1721 6.79402 20.3405C5.62132 19.509 4.66489 18.4085 4.00479 17.1314C3.3447 15.8543 3.00016 14.4376 3 13C3 10.875 3.736 8.922 4.968 7.382L3.515 5.93L4.929 4.516L6.382 5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9497 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9497 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05025 8.05025C5.7375 9.36301 5 11.1435 5 13C5 14.8565 5.7375 16.637 7.05025 17.9497C8.36301 19.2625 10.1435 20 12 20ZM13 12H16L11 18.5V14H8L13 7.495V12ZM8 1H16V3H8V1Z"
                                    fill={currentPath === '/audit' ? '#fff' : "#667085"}/>
                            </svg>
                            {isMenuOpened
                                &&
                                <NavLink
                                    to={'/audit'}
                                >
                                    Audit
                                </NavLink>
                            }

                        </div>
                        :
                        <div
                            className={currentPath === '/audit' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                lineHeight: '0',
                            }}
                            onMouseEnter={() => handleItemHover('Audit')}
                            onMouseLeave={handleItemLeave}
                            onClick={onMenuItemClick}
                        >


                            <NavLink
                                to={'/audit'}
                                style={{
                                    marginLeft: '0',
                                    lineHeight: '0',
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.382 5.968C7.97574 4.69107 9.9578 3.99674 12 4C14.125 4 16.078 4.736 17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C19.9292 8.50526 20.5443 9.82713 20.8258 11.2369C21.1073 12.6467 21.0469 14.1034 20.6498 15.4851C20.2527 16.8668 19.5304 18.1333 18.5433 19.1785C17.5562 20.2237 16.3331 21.0172 14.9764 21.4926C13.6196 21.9681 12.1687 22.1116 10.7452 21.9111C9.32158 21.7107 7.96672 21.1721 6.79402 20.3405C5.62132 19.509 4.66489 18.4085 4.00479 17.1314C3.3447 15.8543 3.00016 14.4376 3 13C3 10.875 3.736 8.922 4.968 7.382L3.515 5.93L4.929 4.516L6.382 5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9497 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9497 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05025 8.05025C5.7375 9.36301 5 11.1435 5 13C5 14.8565 5.7375 16.637 7.05025 17.9497C8.36301 19.2625 10.1435 20 12 20ZM13 12H16L11 18.5V14H8L13 7.495V12ZM8 1H16V3H8V1Z"
                                        fill={currentPath === '/audit' ? '#fff' : "#667085"}/>
                                </svg>
                            </NavLink>
                            {hoveredItem && hoveredItem === 'Audit' && (
                                <div style={{top: 3, left: 53}}
                                     className={currentPath === '/audit' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                    {hoveredItem}
                                </div>
                            )}

                        </div>
                    : null
                }
                {parametersMenuItem
                &&
                parametersMenuItem.IS_ACCESS === 1
                    ?
                    isMenuOpened
                        ?
                        <div
                            className={currentPath === '/parameters'
                            || currentPath === '/parameters/marketing-reports'
                            || currentPath === '/parameters/disclaimer-info'
                            || currentPath === '/parameters/templates-for-email'
                            || currentPath === '/parameters/templates-for-email/email-template'
                            || currentPath === '/parameters/create-activity-codes'
                            || currentPath === '/parameters/report-agents-order'
                            || currentPath === '/parameters/administration'
                                ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            onClick={onMenuItemClick}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                                    stroke={currentPath === '/parameters'
                                    || currentPath === '/parameters/marketing-reports'
                                    || currentPath === '/parameters/disclaimer-info'
                                    || currentPath === '/parameters/templates-for-email'
                                    || currentPath === '/parameters/create-activity-codes'
                                    || currentPath === '/parameters/report-agents-order'
                                    || currentPath === '/parameters/administration'
                                    || currentPath === '/parameters/templates-for-email/email-template' ? '#fff' : "#667085"}
                                    strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {isMenuOpened
                                &&
                                <NavLink
                                    to={'/parameters'}

                                >
                                    Parameters
                                </NavLink>
                            }

                        </div>
                        :
                        <div
                            className={currentPath === '/parameters'
                            || currentPath === '/parameters/marketing-reports'
                            || currentPath === '/parameters/disclaimer-info'
                            || currentPath === '/parameters/templates-for-email'
                            || currentPath === '/parameters/create-activity-codes'
                            || currentPath === '/parameters/report-agents-order'
                            || currentPath === '/parameters/administration'
                            || currentPath === '/parameters/templates-for-email/email-template' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                lineHeight: '0'
                            }}
                            onMouseEnter={() => handleItemHover('Parameters')}
                            onMouseLeave={handleItemLeave}
                            onClick={onMenuItemClick}
                        >
                            <NavLink
                                to={'/parameters'}
                                style={{
                                    marginLeft: '0',
                                    lineHeight: '0',
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                                        stroke={currentPath === '/parameters'
                                        || currentPath === '/parameters/marketing-reports'
                                        || currentPath === '/parameters/disclaimer-info'
                                        || currentPath === '/parameters/templates-for-email'
                                        || currentPath === '/parameters/create-activity-codes'
                                        || currentPath === '/parameters/report-agents-order'
                                        || currentPath === '/parameters/administration'
                                        || currentPath === '/parameters/templates-for-email/email-template' ? '#fff' : "#667085"}
                                        strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </NavLink>
                            {hoveredItem && hoveredItem === 'Parameters' && (
                                <div style={{top: 3, left: 53}}
                                     className={currentPath === '/parameters'
                                     || currentPath === '/parameters/marketing-reports'
                                     || currentPath === '/parameters/disclaimer-info'
                                     || currentPath === '/parameters/templates-for-email'
                                     || currentPath === '/parameters/create-activity-codes'
                                     || currentPath === '/parameters/report-agents-order'
                                     || currentPath === '/parameters/administration'
                                     || currentPath === '/parameters/templates-for-email/email-template' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}
                                     onClick={onMenuItemClick}
                                >
                                    {hoveredItem}
                                </div>
                            )}
                        </div>
                    :
                    null
                }
                {/*{*/}
                {/*    isMenuOpened*/}
                {/*        ?*/}
                {/*        <div*/}
                {/*            className={currentPath === '/faqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}*/}
                {/*            onClick={onMenuItemClick}*/}
                {/*        >*/}
                {/*            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                <path d="M3 5.75C3 5.55109 3.07902 5.36032 3.21967 5.21967C3.36032 5.07902 3.55109 5 3.75 5C6.413 5 9.008 4.057 11.55 2.15C11.6798 2.05263 11.8377 2 12 2C12.1623 2 12.3202 2.05263 12.45 2.15C14.992 4.057 17.587 5 20.25 5C20.4489 5 20.6397 5.07902 20.7803 5.21967C20.921 5.36032 21 5.55109 21 5.75V11C21 16.001 18.042 19.676 12.275 21.948C12.0983 22.0176 11.9017 22.0176 11.725 21.948C5.958 19.676 3 16 3 11V5.75ZM4.5 6.478V11C4.5 15.256 6.953 18.379 12 20.442C17.047 18.379 19.5 15.256 19.5 11V6.478C16.923 6.326 14.42 5.388 12 3.678C9.58 5.388 7.077 6.326 4.5 6.478ZM13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM10.75 9.75C10.75 9.41848 10.8817 9.10054 11.1161 8.86612C11.3505 8.6317 11.6685 8.5 12 8.5C12.3315 8.5 12.6495 8.6317 12.8839 8.86612C13.1183 9.10054 13.25 9.41848 13.25 9.75C13.25 10.197 13.002 10.487 12.47 11.018L12.423 11.065C11.953 11.533 11.25 12.232 11.25 13.35C11.25 13.5489 11.329 13.7397 11.4697 13.8803C11.6103 14.021 11.8011 14.1 12 14.1C12.1989 14.1 12.3897 14.021 12.5303 13.8803C12.671 13.7397 12.75 13.5489 12.75 13.35C12.75 12.9 12.999 12.61 13.53 12.08L13.579 12.03C14.049 11.563 14.75 10.865 14.75 9.75C14.75 9.02065 14.4603 8.32118 13.9445 7.80546C13.4288 7.28973 12.7293 7 12 7C11.2707 7 10.5712 7.28973 10.0555 7.80546C9.53973 8.32118 9.25 9.02065 9.25 9.75C9.25 9.94891 9.32902 10.1397 9.46967 10.2803C9.61032 10.421 9.80109 10.5 10 10.5C10.1989 10.5 10.3897 10.421 10.5303 10.2803C10.671 10.1397 10.75 9.94891 10.75 9.75Z"*/}
                {/*                      fill={currentPath === '/faqs' ? '#fff' : "#667085"}/>*/}
                {/*            </svg>*/}
                {/*            <NavLink*/}
                {/*                aria-disabled={true}*/}
                {/*                to={'/faqs'}*/}
                {/*            >*/}
                {/*                FAQ*/}
                {/*            </NavLink>*/}

                {/*        </div>*/}
                {/*        :*/}
                {/*        <>*/}
                {/*            <div*/}
                {/*                className={currentPath === '/faqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}*/}
                {/*                style={{*/}
                {/*                    display: 'flex',*/}
                {/*                    justifyContent: 'center',*/}
                {/*                    lineHeight: '0'*/}
                {/*                }}*/}
                {/*                onMouseEnter={() => handleItemHover('FAQ')}*/}
                {/*                onMouseLeave={handleItemLeave}*/}
                {/*                onClick={onMenuItemClick}*/}
                {/*            >*/}
                {/*                <NavLink*/}
                {/*                    aria-disabled={true}*/}
                {/*                    to={'/faqs'}*/}
                {/*                    style={{*/}
                {/*                        marginLeft: '0',*/}
                {/*                        lineHeight: '0',*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                        <path d="M3 5.75C3 5.55109 3.07902 5.36032 3.21967 5.21967C3.36032 5.07902 3.55109 5 3.75 5C6.413 5 9.008 4.057 11.55 2.15C11.6798 2.05263 11.8377 2 12 2C12.1623 2 12.3202 2.05263 12.45 2.15C14.992 4.057 17.587 5 20.25 5C20.4489 5 20.6397 5.07902 20.7803 5.21967C20.921 5.36032 21 5.55109 21 5.75V11C21 16.001 18.042 19.676 12.275 21.948C12.0983 22.0176 11.9017 22.0176 11.725 21.948C5.958 19.676 3 16 3 11V5.75ZM4.5 6.478V11C4.5 15.256 6.953 18.379 12 20.442C17.047 18.379 19.5 15.256 19.5 11V6.478C16.923 6.326 14.42 5.388 12 3.678C9.58 5.388 7.077 6.326 4.5 6.478ZM13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM10.75 9.75C10.75 9.41848 10.8817 9.10054 11.1161 8.86612C11.3505 8.6317 11.6685 8.5 12 8.5C12.3315 8.5 12.6495 8.6317 12.8839 8.86612C13.1183 9.10054 13.25 9.41848 13.25 9.75C13.25 10.197 13.002 10.487 12.47 11.018L12.423 11.065C11.953 11.533 11.25 12.232 11.25 13.35C11.25 13.5489 11.329 13.7397 11.4697 13.8803C11.6103 14.021 11.8011 14.1 12 14.1C12.1989 14.1 12.3897 14.021 12.5303 13.8803C12.671 13.7397 12.75 13.5489 12.75 13.35C12.75 12.9 12.999 12.61 13.53 12.08L13.579 12.03C14.049 11.563 14.75 10.865 14.75 9.75C14.75 9.02065 14.4603 8.32118 13.9445 7.80546C13.4288 7.28973 12.7293 7 12 7C11.2707 7 10.5712 7.28973 10.0555 7.80546C9.53973 8.32118 9.25 9.02065 9.25 9.75C9.25 9.94891 9.32902 10.1397 9.46967 10.2803C9.61032 10.421 9.80109 10.5 10 10.5C10.1989 10.5 10.3897 10.421 10.5303 10.2803C10.671 10.1397 10.75 9.94891 10.75 9.75Z"*/}
                {/*                              fill={currentPath === '/faqs' ? '#fff' : "#667085"}/>*/}
                {/*                    </svg>*/}
                {/*                </NavLink>*/}
                {/*                {hoveredItem && hoveredItem === 'FAQ' && (*/}
                {/*                    <div style={{top: 3, left: 53}}*/}
                {/*                         className={currentPath === '/faqs' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>*/}
                {/*                        {hoveredItem}*/}
                {/*                    </div>*/}
                {/*                )}*/}
                {/*            </div>*/}
                {/*        </>*/}
                {/*}*/}
                {/*<div*/}
                {/*    className={currentPath === '/faqs' ? `${s.menu__item} ${s.menu__item_active}` : s.menu__item}*/}
                {/*    style={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'center',*/}
                {/*        lineHeight: '0',*/}
                {/*    }}*/}
                {/*    onMouseEnter={() => handleItemHover('FAQs')}*/}
                {/*    onMouseLeave={handleItemLeave}*/}
                {/*    onClick={onMenuItemClick}*/}
                {/*>*/}


                {/*    <NavLink*/}
                {/*        to={'/faqs'}*/}
                {/*        style={{*/}
                {/*            marginLeft: '0',*/}
                {/*            lineHeight: '0',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M3 5.75C3 5.55109 3.07902 5.36032 3.21967 5.21967C3.36032 5.07902 3.55109 5 3.75 5C6.413 5 9.008 4.057 11.55 2.15C11.6798 2.05263 11.8377 2 12 2C12.1623 2 12.3202 2.05263 12.45 2.15C14.992 4.057 17.587 5 20.25 5C20.4489 5 20.6397 5.07902 20.7803 5.21967C20.921 5.36032 21 5.55109 21 5.75V11C21 16.001 18.042 19.676 12.275 21.948C12.0983 22.0176 11.9017 22.0176 11.725 21.948C5.958 19.676 3 16 3 11V5.75ZM4.5 6.478V11C4.5 15.256 6.953 18.379 12 20.442C17.047 18.379 19.5 15.256 19.5 11V6.478C16.923 6.326 14.42 5.388 12 3.678C9.58 5.388 7.077 6.326 4.5 6.478ZM13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM10.75 9.75C10.75 9.41848 10.8817 9.10054 11.1161 8.86612C11.3505 8.6317 11.6685 8.5 12 8.5C12.3315 8.5 12.6495 8.6317 12.8839 8.86612C13.1183 9.10054 13.25 9.41848 13.25 9.75C13.25 10.197 13.002 10.487 12.47 11.018L12.423 11.065C11.953 11.533 11.25 12.232 11.25 13.35C11.25 13.5489 11.329 13.7397 11.4697 13.8803C11.6103 14.021 11.8011 14.1 12 14.1C12.1989 14.1 12.3897 14.021 12.5303 13.8803C12.671 13.7397 12.75 13.5489 12.75 13.35C12.75 12.9 12.999 12.61 13.53 12.08L13.579 12.03C14.049 11.563 14.75 10.865 14.75 9.75C14.75 9.02065 14.4603 8.32118 13.9445 7.80546C13.4288 7.28973 12.7293 7 12 7C11.2707 7 10.5712 7.28973 10.0555 7.80546C9.53973 8.32118 9.25 9.02065 9.25 9.75C9.25 9.94891 9.32902 10.1397 9.46967 10.2803C9.61032 10.421 9.80109 10.5 10 10.5C10.1989 10.5 10.3897 10.421 10.5303 10.2803C10.671 10.1397 10.75 9.94891 10.75 9.75Z"*/}
                {/*                  fill={currentPath === '/faqs' ? '#fff' : "#667085"}/>*/}
                {/*        </svg>*/}
                {/*        {isMenuOpened*/}
                {/*            &&*/}
                {/*            <NavLink*/}
                {/*                to={'/faqs'}*/}

                {/*            >*/}
                {/*                FAQ*/}
                {/*            </NavLink>*/}
                {/*        }*/}
                {/*    </NavLink>*/}
                {/*    {hoveredItem && hoveredItem === 'FAQs' && (*/}
                {/*        <div style={{top: 3, left: 53}}*/}
                {/*             className={currentPath === '/faqs' ? `${s.menu__item_hovered} ${s.menu__item_hovered_black}` : `${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>*/}
                {/*            {hoveredItem}*/}
                {/*        </div>*/}
                {/*    )}*/}

                {/*</div>*/}
            </>

            {
                isGoogleIntegrated ? (
                    <div
                        className={`${s.integrate_with_google} ${s.integrated} ${isMenuOpened ? '' : s.small_menu}`}
                        onClick={() => isMenuOpened ? {} : logoutFromGoogle()}
                        onMouseEnter={() => handleItemHover('Google integration')}
                        onMouseLeave={handleItemLeave}
                    >
                        <div className={s.banner_title}>
                            <GoogleIntegratedIcon/>
                            <span>Google calendar</span>
                            <DoneIcon className={s.done_icon}/>
                        </div>
                        <div className={s.integration_description}>
                            You have integrated with Google calendar
                        </div>
                        <div>
                            <Button onClick={logoutFromGoogle}>
                                Logout
                            </Button>
                        </div>
                        {hoveredItem && !isMenuOpened && hoveredItem === 'Google integration' && (
                            <div style={{top: 375, left: 53, position: "absolute", width: '135px'}}
                                 className={`${s.menu__item_hovered} ${s.menu__item_hovered_white}`}>
                                {hoveredItem}
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        onClick={() => isMenuOpened ? {} : integrateWithGoogle()}
                        className={`${s.integrate_with_google} ${isMenuOpened ? '' : s.small_menu}`}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            lineHeight: '0'
                        }}
                    >
                        <div className={s.banner_title}>
                            <GoogleCalendarIcon/>
                            <span>Integration</span>
                        </div>
                        <div className={s.integration_description}>
                            Allow to integrate with Google calendar
                        </div>
                        <Button onClick={integrateWithGoogle}>
                            Integrate
                        </Button>
                    </div>
                )}

            <button className={s.hideButton} onClick={onHideMenu}>
                <svg style={{
                    transform: isMenuOpened ? 'none' : 'rotate(180deg)'
                }} width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="24" rx="4" fill="#E2E2E2"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M26.7062 17.6931C26.5187 17.8805 26.2644 17.9858 25.9992 17.9858C25.7341 17.9858 25.4798 17.8805 25.2922 17.6931L20.2922 12.6931C20.1048 12.5055 19.9994 12.2512 19.9994 11.9861C19.9994 11.7209 20.1048 11.4666 20.2922 11.2791L25.2922 6.27905C25.4808 6.09689 25.7334 5.9961 25.9956 5.99838C26.2578 6.00066 26.5086 6.10583 26.6941 6.29123C26.8795 6.47664 26.9846 6.72745 26.9869 6.98965C26.9892 7.25185 26.8884 7.50445 26.7062 7.69305L22.4132 11.9861L26.7062 16.2791C26.8937 16.4666 26.999 16.7209 26.999 16.9861C26.999 17.2512 26.8937 17.5055 26.7062 17.6931ZM20.7062 17.6931C20.5187 17.8805 20.2644 17.9858 19.9992 17.9858C19.7341 17.9858 19.4798 17.8805 19.2922 17.6931L14.2922 12.6931C14.1048 12.5055 13.9994 12.2512 13.9994 11.9861C13.9994 11.7209 14.1048 11.4666 14.2922 11.2791L19.2922 6.27905C19.4808 6.09689 19.7334 5.9961 19.9956 5.99838C20.2578 6.00066 20.5086 6.10583 20.6941 6.29123C20.8795 6.47664 20.9846 6.72745 20.9869 6.98965C20.9892 7.25185 20.8884 7.50445 20.7062 7.69305L16.4132 11.9861L20.7062 16.2791C20.8937 16.4666 20.999 16.7209 20.999 16.9861C20.999 17.2512 20.8937 17.5055 20.7062 17.6931Z"
                          fill="#2D2D2D"/>
                </svg>

            </button>
        </div>
    );
};

export default Sidebar;
