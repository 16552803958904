import {
    GetRequirementsByContactIdGridDataThunk,
    GetRequirementsGridDataThunk,
    onSetFiltersForRequirementsGrid,
    onSetIsSearchContactsLoading,
    onSetReqSearchedByContactsLocalSortModel,
    onSetRequirementsLocalSortModel,
    onSetSearchedByContactsFiltersForRequirementsGrid,
    setRequirementsSearchedByContactsShownColumns,
    setRequirementsShownColumns
} from "../store/requirementsReducer";
import {
    GetPropertiesGridThunk, GetSurfaceGridThunk,
    onSetLocalSortModel,
    onSetPropertiesGridLoaderMessage,
    ResetAllGridConfigsThunk,
    setGridPropertiesColumns, setShownColumns
} from "../store/propertiesReducer";
import {AppDispatch} from "../store/store";
import {UserDataType} from "../types/userTypes";
import {SystemGridsTypes} from "../types/commonGridsTypes";
import {gridDataHelper, utilityDataHelper} from "../helpers/localStorageHelper";
import {onSetFiltersForAuditGrid} from "../store/parametersReducer";
import {onSetFiltersForActivitiesGrid} from "../store/activitesGrid";
import {GridColumns} from "../types/commonTypes";
import {gridApi} from "../app/api";
import {message} from "antd";

export const resetGridConfigs = (
    dispatch: AppDispatch,
    userData: UserDataType,
    threeMonthsAgo: Date,
    today: Date,
    setSearchTerm: (search: string) => void,
    setLocalSortModel: (model: {}) => void,
    gridType: SystemGridsTypes,
    reqId?: number | null,
    isInitialGridColumnsCall?: boolean,
) => {
    if(gridType === 'Requirements'){
        dispatch(setRequirementsShownColumns({columns: []}))
        dispatch(setGridPropertiesColumns([]))
        setSearchTerm('')
        dispatch(onSetRequirementsLocalSortModel({sort_model: []}));
        dispatch(onSetFiltersForRequirementsGrid({gridFilters: []}))
        setLocalSortModel({})
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: null,
            grid_type: 'REQ_DATA'
        }))
            .then(() => {
                dispatch(GetRequirementsGridDataThunk({
                    p_agent_ref: Number(userData.user_ref),
                    p_sort_order: '',
                    p_start_period: threeMonthsAgo,
                    p_end_period: today
                }))
            })
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }
    else if(gridType === 'Requirements/Searched by contacts'){
        const contactRef = utilityDataHelper.getSearchedRequirementsContactRef()
        dispatch(setRequirementsSearchedByContactsShownColumns({columns: []}))
        setSearchTerm('')
        dispatch(onSetReqSearchedByContactsLocalSortModel({sort_model: []}));
        dispatch(onSetSearchedByContactsFiltersForRequirementsGrid({gridFilters: []}))
        setLocalSortModel({})
        dispatch(onSetIsSearchContactsLoading(true))
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: null,
            grid_type: 'REQS_CONTACT'
        }))
            .then(() => {
                dispatch(GetRequirementsByContactIdGridDataThunk({
                    p_agent_ref: Number(userData.user_ref),
                    p_contact_ref: contactRef!!,
                    p_sort_order: ''
                }))
                setTimeout(() => {
                    dispatch(onSetIsSearchContactsLoading(false))
                }, 1000)
            })
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }
    else if(gridType === 'Properties' || gridType === 'Surfaces'){
        const columnsWithSorting = gridDataHelper.getPropertiesGridColumnsWithSorting()
        dispatch(setShownColumns({columns: [], gridType: gridType}))
        dispatch(setGridPropertiesColumns([]))
        setSearchTerm('')
        // setSortModel([])
        dispatch(onSetLocalSortModel({sort_model: [], gridType: gridType}))
        setLocalSortModel({})
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: reqId!!,
            grid_type: gridType === 'Properties' ? null : 'WE_SURFPROPDETAILS'
        }))
            .then(() => {
                if (gridType === 'Properties') {
                    dispatch(GetPropertiesGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_row_req: reqId!!,
                            p_sort_order: null,
                            page: 0,
                            size: 0,
                        },
                        isInitialCall: isInitialGridColumnsCall
                    }))
                } else {
                    dispatch(GetSurfaceGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_row_req: reqId!!,
                            p_sort_order: null,
                            page: 0,
                            size: 0,
                            p_prop_req: null
                        }
                    }))
                }

            })
        const propertyIndex = columnsWithSorting.properties.findIndex((property: any) => Number(property.gridPropId) === Number(reqId!!));
        if (Number(propertyIndex) !== -1 && columnsWithSorting.properties.length !== 1) {
            const filteredColumns = columnsWithSorting.properties.filter((c: any) => c.gridPropId !== reqId!!)
            gridDataHelper.setPropertiesGridColumnsWithSorting({
                properties: filteredColumns.map((column: any) => {
                    return {
                        gridColumns: column.gridColumns,
                        gridPropId: column.gridPropId,
                    };
                })
            })
        } else if (columnsWithSorting.properties.length === 1 && Number(propertyIndex) !== -1) {
            gridDataHelper.removePropertiesGridColumnsWithSorting()
        }
        dispatch(onSetFiltersForAuditGrid({gridFilters: []}))
        dispatch(onSetFiltersForActivitiesGrid({gridFilters: []}))
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }

}