import React, {useEffect, useMemo, useRef, useState} from 'react';
import s from './AllPropertiesAddressForm.module.scss';
import styles from './../AddressForm/AddressFrom.module.scss';
import AddressFormInputs from "../AddressForm/AddressFormInputs/AddressFormInputs";
import UniversalInput from "../common/UniversalInput/UniversalInput";
import {ReactComponent as AddressWarningIcon} from './../../img/icons/problemWithAddressIcon.svg';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetPropertiesCurrentPropertyMapAddress,
    onChangeIsAddressModalOpened, selectCountriesArray,
    selectCurrentAddressPropertyData, selectCurrentPropertyMapAddressData,
    UpdateAddressGridAddressProperty
} from "../../store/propertiesReducer";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import "leaflet-defaulticon-compatibility";
import L, {LatLngLiteral} from 'leaflet';
import {AddressPropertyUpdated} from "../../app/api";
import classes from "../ParametersPage/MarketingReports/UploadTemplatesModal/UploadTemplatesModal.module.css";
import {Button, message, Switch} from "antd";
import {CircularProgress, circularProgressClasses} from '@mui/material';
import Box from "@mui/material/Box";
import {CircularProgressProps} from "@material-ui/core";
import Tooltip from "../Tooltip/Tooltip";
import {onSetMapPropertiesIsFreePosition, selectAddressMapIsFreePosition} from '../../store/propertiesMap';


const AllPropertiesAddressForm: React.FC = () => {
    const dispatch = useAppDispatch()
    const markerRef = useRef<L.Marker>(null);
    const currentAddressPropertyData = useAppSelector(selectCurrentAddressPropertyData);
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchPlace, setSearchPlace] = useState<any | null>(null);
    const [position, setPosition] = useState<L.LatLng | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const countriesArray = useAppSelector(selectCountriesArray)
    const [isEmptyInput, setIsEmptyInput] = useState(true)
    const currentAddressData = useAppSelector(selectCurrentPropertyMapAddressData)
    const isFreePosition = useAppSelector(selectAddressMapIsFreePosition)
    const [showMainAddress, setIsShowMainAddress] = useState(false)

    const [formInputs, setFormInputs] = useState([
        {inputName: 'Main address', inputValue: currentAddressPropertyData?.ADDR_STREET_ADDRESS || ''},
        {inputName: 'Country', inputValue: ''},
        {inputName: 'State', inputValue: ''},
        {inputName: 'Postcode', inputValue: currentAddressPropertyData?.ADDR_ZIP || ''},
        {inputName: 'City', inputValue: currentAddressPropertyData?.ADDR_CITY || ''},
        {inputName: 'Quarter', inputValue: currentAddressPropertyData?.ADDR_QUARTER || ''},
        {inputName: 'Street', inputValue: currentAddressPropertyData?.ADDR_STREET || ''},
        {inputName: 'First No.', inputValue: ''},
        {inputName: 'Second No.', inputValue: ''},
        {inputName: 'Coordinates', inputValue: ''},
    ]);

    const [updatedPropertyAddress, setUpdatedPropertyAddress] = useState<AddressPropertyUpdated>({
        addr_city: '',
        addr_nf_no_from: null,
        addr_nf_no_to: null,
        addr_no_connect: null,
        addr_no_from_rest: null,
        addr_no_to_rest: null,
        addr_quarter: null,
        addr_state_admin_level_1: null,
        addr_street: null,
        addr_zip: null,
        cntry_desc_local: null,
        is_default_addr: false,
        prop_addr_ref: currentAddressPropertyData!.PK_PROPERTY_ADDRESS,
        prop_ref: currentAddressPropertyData!.REF,
        longitude: 0,
        latitude: 0
    })

    const handleInputChange = (inputName: string, inputValue: string) => {
        const street = formInputs.find((i: any) => i.inputName === 'Street')
        const firstNo = formInputs.find((i: any) => i.inputName === 'First No.')
        const secondNo = formInputs.find((i: any) => i.inputName === 'Second No.')
        const updatedFormInputs = formInputs.map((input) => input.inputName === inputName ? {
                ...input,
                inputValue
            } : input.inputName === 'Main address' ? {
                    ...input,
                    inputValue: `${street?.inputValue !== '' ? street : ''} ${firstNo?.inputValue !== '' ? firstNo : ''} ${secondNo?.inputValue !== '' ? secondNo : ''}`
                }
                : input.inputName === 'Coordinates' ? {
                    ...input,
                    inputValue: ''
                } : input
        );
        setFormInputs(updatedFormInputs);
        if (inputName === 'Street') {
            setSearchInput(`${inputValue + ' '}${firstNo?.inputValue !== '' ? firstNo?.inputValue + ' ' : ''}${secondNo?.inputValue !== '' ? secondNo?.inputValue + ' ' : ''}`)
        } else if (inputName === 'First No.') {
            setSearchInput(`${street?.inputValue !== '' ? street?.inputValue + ' ' : ''}${inputValue}${secondNo?.inputValue + ' ' !== '' ? secondNo?.inputValue + ' ' : ''}`)
        } else if (inputName === 'Second No.') {
            setSearchInput(`${street?.inputValue !== '' ? street?.inputValue + ' ' : ''}${firstNo?.inputValue !== '' ? firstNo?.inputValue + ' ' : ''} ${inputValue + ' '}`)
        }
        setSearchPlace(null)

    };
    const coordinates = formInputs.find((i: any) => i.inputName === 'Coordinates')


    // Function to convert country code to full country name
    const getFullCountryName = (countryCode: string) => {
        return countriesArray.find((country: any) => country.cntry_sign === countryCode)?.cntry_desc_default; // Default to original code if mapping not found
    };

    useEffect(() => {
        const city = formInputs.find((i: any) => i.inputName === 'City')
        const street = formInputs.find((i: any) => i.inputName === 'Main address')
        const postCode = formInputs.find((i: any) => i.inputName === 'Postcode')
        const quarter = formInputs.find((i: any) => i.inputName === 'Quarter')
        if(street){
            setIsEmptyInput(false)
        }
        getAddressInfo(false, street?.inputValue, city?.inputValue, '', postCode?.inputValue, '', quarter?.inputValue);
        // eslint-disable-next-line
    }, []);

    const getAddressInfo = async (isSecondApiCall: boolean, streetAddress?: string, city?: string, state?: string, postalCode?: string, country?: string, quarter?: string, withJustCityAddress?: boolean) => {
        setLoading(true)
        try {
            const headers = new Headers();
            headers.append('accept-language', 'en');
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?street=${streetAddress || ''}&city=${city?.toLowerCase().replace(/\d+/g, '').trim() === 'praha' ? 'Hlavní město Praha' : city?.replace(/\d+/g, '') || ''}&format=json&polygon_geojson=1&limit=1&addressdetails=1&country=${country || ''}&state=${state || ''}&quarter=${quarter || ''}`,
                {headers}
            );
            if (response.ok) {
                const data = await response.json();
                if (data && data.length > 0) {
                    setSearchPlace(data[0]);
                    if (data.address && data.address.country_code) {
                        data.address.country = getFullCountryName(data.address.country_code);
                    }
                    getAddressInfoFromPosition({lat: data[0].lat, lng: data[0].lon}, isFreePosition);
                } else {
                    if(!isSecondApiCall){
                        setSearchPlace(null);
                        getAddressInfoWithoutAddressNumbers(isSecondApiCall, quarter, city, '', '', '', quarter)
                    }
                    else if(withJustCityAddress){
                        getAddressInfoWithoutAddressNumbers(isSecondApiCall, '', 'praha', '', '', '', '', true)
                    }
                    // else{
                    //     setSearchPlace(null);
                    //     if(isSecondApiCall){
                    //         getAddressInfoWithoutAddressNumbers(isSecondApiCall, '', 'praha', '', '', '', '')
                    //         message.warn('No search results found, please try again with different address', 5)
                    //     }
                    //     // message.warn('No search results found, please try again with different address', 5)
                    //
                    // }

                }
            } else {

                throw new Error('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address information:', error);
        }
    };

    const getAddressInfoWithoutAddressNumbers = async (isSecondApiCall: boolean, streetAddress?: string, city?: string, state?: string, postalCode?: string, country?: string, quarter?: string, isDefaultCityParam?: boolean) => {
        try {
            const headers = new Headers();
            headers.append('accept-language', 'en');
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?street=${streetAddress || ''}&city=${city?.toLowerCase().replace(/\d+/g, '').trim() === 'praha' ? 'Hlavní město Praha' : city?.replace(/\d+/g, '') || ''}&format=json&polygon_geojson=1&limit=1&addressdetails=1&country=${country || ''}&state=${state || ''}&quarter=${quarter || ''}`,
                {headers}
            );
            if (response.ok) {
                const data = await response.json();
                if (data && data.length > 0) {
                    setSearchPlace(data[0]);
                    if (data.address && data.address.country_code) {
                        data.address.country = getFullCountryName(data.address.country_code);
                    }
                    getAddressInfoFromPosition({lat: data[0].lat, lng: data[0].lon}, isFreePosition);
                    if(isDefaultCityParam){
                        message.warn('No search results found, please try again with different address', 5)
                    }
                } else {
                    setSearchPlace(null);
                    // if(isSecondApiCall){
                    //     message.warn('No search results found, please try again with different address', 5)
                    // }
                    // message.warn('No search results found, please try again with different address', 5)
                    getAddressInfo(true, '', city, '', '', '', '', true)
                }
            } else {

                throw new Error('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address information:', error);
        }
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;

        setSearchInput(query);
        setIsEmptyInput(false)
        setSearchPlace(null)
        const parts = query.split(' ');
        let streetName: string
        let houseNumber: string
        let apartmentNumber: string
        if (parts.length === 1) {
            streetName = parts[0]
        } else {
            streetName = parts.slice(0, -1).join(' ');
            houseNumber = parts[parts.length - 1].split('/')[0];
            apartmentNumber = parts[parts.length - 1].split('/')[1];
        }


        setFormInputs((prevFormInputs) => {
            return prevFormInputs.map((input) => {
                if (input.inputName === 'Street') {
                    return {...input, inputValue: streetName}
                } else if (input.inputName === 'First No.') {
                    return {...input, inputValue: houseNumber}
                } else if (input.inputName === 'Second No.') {
                    return {...input, inputValue: apartmentNumber}
                } else if (input.inputName === 'Coordinates') {
                    return {...input, inputValue: ''}
                } else if (input.inputName === 'Main address') {
                    return {...input, inputValue: query}
                } else {
                    return input;
                }
            });
        });

    };


    const OSMMap: React.FC = () => {
        const map = useMap();
        // Scroll the map to the searched address when searchPlace is available
        useEffect(() => {
            if (searchPlace) {
                const zoom: number = map.getZoom();
                const { lat, lon } = searchPlace;
                map.setView([lat, lon], zoom);
            }
            // eslint-disable-next-line
        }, [map, searchPlace]);

        useMapEvents({
            click: (e: { latlng: LatLngLiteral }) => {
                setIsEmptyInput(false);
                const zoom: number = map.getZoom();
                getAddressInfoFromPosition({ lat: e.latlng.lat, lng: e.latlng.lng }, isFreePosition);
                map.setView([e.latlng.lat, e.latlng.lng], zoom);
            },
            locationfound: (location: any) => {
                console.log('location found:', location);
            },
        });

        return null;
    };


    const onCloseModal = () => {
        dispatch(onChangeIsAddressModalOpened(false))
        setPosition(null)
    }




    const getAddressInfoFromPosition = async (position: any, isFreePosition: boolean) => {
        const city = formInputs.find((i: any) => i.inputName === 'City')
        const quarter = formInputs.find((i: any) => i.inputName === 'Quarter')
        try {
            const headers = new Headers();
            headers.append('accept-language', 'cz');
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&polygon_geojson=1&lat=${position.lat}&lon=${position.lng}&zoom=18&city=${city?.inputValue}&quarter=${quarter?.inputValue}`,
                {headers}
            );

            if (response.ok) {
                const data = await response.json();
                const dataForBackRequest = {
                    ...data,
                    lon: Number(data.lon).toFixed(10),
                    lat: Number(data.lat).toFixed(10)
                }
                const jsonString = JSON.stringify(dataForBackRequest);
                dispatch(GetPropertiesCurrentPropertyMapAddress(jsonString))
                setSearchPlace(data);
                setLoading(false)

                if (isFreePosition) {
                    // @ts-ignore
                    setPosition([position.lat, position.lng]);
                } else {
                    // @ts-ignore
                    setPosition([data.lat, data.lon])
                }
                const cityAddress = getFullCountryName(data?.address?.country_code);
                if (data.address && data.address.country_code) {
                    data.address.country = cityAddress
                }

                setUpdatedPropertyAddress({
                    addr_city: data.address.city === 'Hlavní město Praha' ? 'Praha' : data.address.city || data.address.village || data.address.town,
                    addr_nf_no_from: Number(data?.address?.house_number?.split('/')[0]) !== 0 ? Number(data?.address?.house_number?.split('/')[0]) : null,
                    addr_nf_no_to: Number(data?.address?.house_number?.split('/')[1]) !== 0 ? Number(data?.address?.house_number?.split('/')[1]) : null,
                    addr_no_connect: data?.address?.house_number?.split('/')[0] || data?.address?.house_number?.split('/')[1] ? '/' : null,
                    addr_no_from_rest: (data?.address?.house_number?.split('/')[0] || '').replace(/[^a-zA-Z]/g, '') || null,
                    addr_no_to_rest: (data?.address?.house_number?.split('/')[1] || '').replace(/[^a-zA-Z]/g, '') || null,
                    addr_quarter: data.address.quarter,
                    addr_state_admin_level_1: data.address.state,
                    addr_street: `${data.address.road}`,
                    addr_zip: data.address.postcode,
                    cntry_desc_local: data.address.country,
                    is_default_addr: false,
                    prop_addr_ref: currentAddressPropertyData!.PK_PROPERTY_ADDRESS,
                    prop_ref: currentAddressPropertyData!.REF,
                    longitude: isFreePosition ? position.lng : data.lon,
                    latitude: isFreePosition ? position.lat : data.lat
                })
                setFormInputs((prevFormInputs) => {
                    return prevFormInputs.map((input) => {
                        if (input.inputName === 'Country') {
                            return {...input, inputValue: cityAddress}
                        } else if (input.inputName === 'Main address') {
                            const address = data.address.road ? data.address.road : ''
                            const addressNo = data.address.house_number ? data.address.house_number : ''
                            if (address || addressNo) {
                                setSearchInput(address + ' ' + addressNo || '')
                            } else {
                                setSearchInput('')
                            }
                            return {...input, inputValue: data.address.road + ' ' + addressNo || ''};
                        } else if (input.inputName === 'State') {
                            return {...input, inputValue: data.address.state || ''};
                        } else if (input.inputName === 'Postcode') {
                            return {...input, inputValue: data.address.postcode || ''};
                        } else if (input.inputName === 'City') {
                            return {...input, inputValue: data.address.city === 'Hlavní město Praha' ? 'Praha' : data.address.city || data.address.village || data.address.town || ''};
                        } else if (input.inputName === 'Quarter') {
                            return {...input, inputValue: data.address.quarter || ''};
                        } else if (input.inputName === 'Street') {
                            return {...input, inputValue: data.address.road || ''};
                        } else if (input.inputName === 'First No.') {
                            return {...input, inputValue: data?.address?.house_number?.split('/')[0] || ''};
                        } else if (input.inputName === 'Second No.') {
                            return {...input, inputValue: data?.address?.house_number?.split('/')[1] || ''};
                        } else if (input.inputName === 'Coordinates') {
                            return {
                                ...input,
                                inputValue: `${isFreePosition ? position.lat : data.lat}, ${isFreePosition ? position.lng : data.lon}`
                            };
                        } else {
                            return input;
                        }
                    });
                });
            } else {
                throw new Error('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address information:', error);
        } finally {
            setLoading(false)
        }
    };

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null){
                    setPosition(marker.getLatLng());
                    getAddressInfoFromPosition(marker.getLatLng(), isFreePosition);
                }
            },
        }),
        // eslint-disable-next-line
        [isFreePosition], // Add isFreePosition to the dependency array
    );



    const onConfirmAddress = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false);
            // Close the InfoWindow when the confirm button is clicked
            if (markerRef.current) {
                markerRef.current.closePopup();
            }
        }, 2000);
    }

    const onUpdatePropertyAddress = () => {
        if (searchInput === '' || coordinates?.inputValue === '') {
            message.warn('Please fill the fields and press search coordinates button or place a marker on map to find your address in order to save it', 7)
        } else {
            const requestData = {
                ...updatedPropertyAddress,
                cntry_desc_local: updatedPropertyAddress.cntry_desc_local,
                is_default_addr: false,
                prop_addr_ref: currentAddressPropertyData!.PK_PROPERTY_ADDRESS,
                prop_ref: currentAddressPropertyData!.REF,
                longitude: Number(updatedPropertyAddress.longitude),
                latitude: Number(updatedPropertyAddress.latitude),
                addr_nf_no_from: isNaN(updatedPropertyAddress.addr_nf_no_from!!) ? null : updatedPropertyAddress.addr_nf_no_from,
                addr_nf_no_to: isNaN(updatedPropertyAddress.addr_nf_no_to!!) ? null : updatedPropertyAddress.addr_nf_no_to,
                addr_quarter: updatedPropertyAddress.addr_quarter ? updatedPropertyAddress.addr_quarter : null
            }
            dispatch(UpdateAddressGridAddressProperty(requestData))
        }

    }

    const onFindAddress = () => {
        const city = formInputs.find((i: any) => i.inputName === 'City')
        const country = formInputs.find((i: any) => i.inputName === 'Country')
        const state = formInputs.find((i: any) => i.inputName === 'State')
        const postCode = formInputs.find((i: any) => i.inputName === 'Postcode')
        getAddressInfo(false, searchInput, city?.inputValue, state?.inputValue, postCode?.inputValue, country?.inputValue)
    }


    const onSwitchChange = (checked: boolean) => {
        dispatch(onSetMapPropertiesIsFreePosition(checked))
    };

    const onShownSwitchChange = (checked: boolean) => {
        setIsShowMainAddress(checked)
    };

    return (
        <div className={s.inner}>
            <div className={s.inner__content}>
                <div className={s.inner__header}>
                    <AddressWarningIcon/>
                    <p className={s.inner__headerTitle}>Property {currentAddressPropertyData?.REF} {currentAddressPropertyData?.ADDR_STREET}</p>
                    <p className={s.inner__headerText}>Add for a property an address or select an address on the map and
                        save it.</p>

                </div>
                <div className={s.formWithMap}>
                    <div className={s.formWithMap__content}>
                        <div style={{
                            maxWidth: '426px',
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            marginRight: '24px'
                        }}>
                            <div className={s.positionSwitcher}>
                                <div className={s.positionSwitcher__content}>
                                    <p className={s.positionSwitcher__positionText}>Free position</p>
                                    <p className={s.positionSwitcher__abilityText}>Ability to point to any location on
                                        the map and get address coordinates</p>
                                </div>
                                <Switch checked={isFreePosition} onChange={onSwitchChange}/>
                            </div>
                            <UniversalInput
                                type="text"
                                value={searchInput}
                                onChange={handleSearchChange}
                                labelText={'Main address'}
                                withLabel
                                lineDirection="vertical"
                                className={`${styles.formInput}`}
                                labelClassName={styles.formLabel}
                                showError={isEmptyInput && !isFreePosition}
                                errorMessage={'Enter the correct address.'}
                                mainAddress={currentAddressPropertyData?.ADDR_STREET_ADDRESS}
                                showMainAddress={showMainAddress}
                            />
                            <AddressFormInputs
                                formInputs={formInputs}
                                onInputChange={handleInputChange}
                                onFindAddress={onFindAddress}
                                isAddressFilled={searchInput !== '' && coordinates?.inputValue !== ''}
                                disabled={isEmptyInput && !isFreePosition}
                                isFreePosition={isFreePosition}
                                mainAddress={currentAddressPropertyData?.ADDR_CITY}
                                showMainAddress={showMainAddress}

                            />
                            <div className={s.positionSwitcher} style={{
                                marginTop: '12px'
                            }}>
                                <div className={s.positionSwitcher__content}>
                                    <p className={s.positionSwitcher__positionText}>Original address</p>
                                    <p className={s.positionSwitcher__abilityText}>Ability to view the original address
                                        (a property that does not have an address or for which geocoordinates are not automatically determined). </p>
                                </div>
                                <Switch checked={showMainAddress} onChange={onShownSwitchChange}/>
                            </div>
                        </div>
                        {isLoading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(255, 255, 255, 0.8)',
                                    zIndex: 1000,
                                }}
                            >
                                <FacebookCircularProgress/>
                            </div>
                        )}
                        <MapContainer
                            center={position || [0, 0]} // Use the position state for the center
                            zoom={15}
                            style={{height: '532px', width: '100%'}}
                            scrollWheelZoom={true}
                            // bounds={polygons}
                        >
                            <div style={{
                                display: 'none'
                            }}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                            </div>

                            <OSMMap/>
                            {searchPlace && (
                                <Marker
                                    eventHandlers={eventHandlers}
                                    ref={markerRef}
                                    position={position || [searchPlace.lat, searchPlace.lon]}
                                    draggable={true}
                                >
                                    <Popup className={s.confirmPopup}>
                                        {searchPlace.display_name}
                                        <button onClick={onConfirmAddress} className={s.confirmPopup__button}>Confirm
                                        </button>
                                    </Popup>
                                </Marker>
                            )}
                            {/*{*/}
                            {/*    mapItems.map((i: any) => {*/}

                            {/*        if(i.type === 'Point'){*/}
                            {/*            return(*/}

                            {/*                    <Circle center={i?.coordinates}  radius={200} />*/}


                            {/*            )*/}
                            {/*        }*/}
                            {/*        else{*/}
                            {/*            console.log(i.coordinates)*/}
                            {/*            return(*/}

                            {/*                    <Polygon positions={i.coordinates}  color="red"/>*/}

                            {/*                )*/}
                            {/*        }*/}
                            {/*    })*/}
                            {/*}*/}

                            {/*<Polygon positions={coordinatess}  color="red"/>*/}
                        </MapContainer>
                    </div>
                </div>
                <div>
                    <div className={classes.btnWrapper}>
                        <Button
                            type='default'
                            style={{
                                width: '289px',
                                height: '44px',
                            }}
                            onClick={onCloseModal}
                        >
                            Cancel
                        </Button>
                        {
                            searchInput === '' || coordinates?.inputValue === ''
                                ?
                                <Tooltip text="Enter the correct address and find its coordinates on the map"
                                         classname={'addressButtonsTooltip '}>
                                    <Button
                                        type={'primary'}
                                        style={searchInput !== '' && coordinates?.inputValue !== '' ? {
                                                height: '44px',
                                                backgroundColor: '#0707FA',
                                                width: '289px',
                                                border: '1px solid #0707FA',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                            }
                                            :
                                            {
                                                width: '289px',
                                                height: '44px',
                                                backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                border: '1px solid transparent',
                                                color: "#FFF"
                                            }
                                        }
                                        onClick={onUpdatePropertyAddress}
                                        disabled={searchInput === '' || coordinates?.inputValue === ''}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>
                                :
                                <Button
                                    type={'primary'}
                                    style={searchInput !== '' && coordinates?.inputValue !== '' ? {
                                            height: '44px',
                                            backgroundColor: '#0707FA',
                                            width: '289px',
                                            border: '1px solid #0707FA',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                        }
                                        :
                                        {
                                            width: '289px',
                                            height: '44px',
                                            backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                            border: '1px solid transparent',
                                            color: "#FFF"
                                        }
                                    }
                                    onClick={onUpdatePropertyAddress}
                                    disabled={searchInput === '' || coordinates?.inputValue === ''}
                                >
                                    Save
                                </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export function FacebookCircularProgress(props: CircularProgressProps) {
    return (
        <Box sx={{position: 'relative'}}>
            {/*// @ts-ignore*/}
            <CircularProgress variant="determinate"
                              sx={{
                                  color: (theme: any) =>
                                      theme.palette.grey[theme.palette.mode === 'light' ? 'rgba(7, 7, 250, 0.5)' : 'rgba(7, 7, 250, 0.5)'],
                              }}
                              size={props.size && 100}
                              thickness={4}
                              {...props}
                              value={100}
            />
            {/*// @ts-ignore*/}
            <CircularProgress variant="indeterminate"
                              disableShrink
                              sx={{
                                  color: (theme) => (theme.palette.mode === 'light' ? '#0707FA' : '#0707FA'),
                                  animationDuration: '550ms',
                                  position: 'absolute',
                                  left: 0,
                                  [`& .${circularProgressClasses.circle}`]: {
                                      strokeLinecap: 'round',
                                  },
                              }}
                              size={props.size && 100}
                              thickness={4}
                              {...props}
                              value={10}
            />
        </Box>
    );
}

export default AllPropertiesAddressForm;
